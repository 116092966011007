import * as React from 'react';
import './Group.css';
import { inject, observer } from 'mobx-react';
import { AppStore } from '../../../../../stores';
import { IGroup } from '../../../../../interfaces/IGroup';
import GroupService from '../../../../../services/GroupService';
import { IMyGroup } from '../../../../../interfaces/IMyGroup';

export interface IProps {
  appStore?: AppStore;
  group: IMyGroup;
  getMyGroups: (group: IMyGroup) => void;
}

export interface IState {
  hasAccess: boolean;
}

@inject('appStore')
@observer
export default class Group extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      hasAccess: false
    }
  }

  render() {
    return (
      <div className='group'>
        <h2>{this.props.group?.group?.name}</h2>
        <p>{this.props.group?.group?.description}</p>
        <div className="shareTypeToggle">
          <input
            type="checkbox"
            checked={this.props.group?.groupMemberInfo != undefined}
            disabled={this.props.appStore.myResult == undefined}
            onChange={() => {
              if (this.props.group?.groupMemberInfo != undefined) {
                GroupService.deleteGroupMemberInfo(this.props.group.groupMemberInfo.objectId).then(() => {
                  this.props.getMyGroups(this.props.group);
                });
              } else {
                this.props.group.groupMemberInfo = {
                  groupId: this.props.group.group.objectId,
                  userId: this.props.appStore.user.id,
                  type: this.props.appStore.selectedType
                };
                GroupService.createGroupMemberInfo(this.props.group.groupMemberInfo).then((id: string) => {
                  if (id != undefined) {
                    this.props.group.groupMemberInfo.objectId = id;
                    this.props.getMyGroups(this.props.group);
                  }
                })
              }
            }}
            style={{
              width: 20,
            }}
          />
          {window.loc.strings.shareType.toUpperCase()}
        </div>
        <div
          className="enneagrammyCreateGroupBtn"
          onClick={() => {
            this.props.appStore.groupToView = this.props.group.group;
          }}
        >
          {window.loc.strings.seeGroupResults.toUpperCase()}
        </div>
        <div
          className="enneagrammyCreateGroupBtn"
          onClick={() => {
            this.props.appStore.groupToEdit = this.props.group.group;
          }}
        >
          {window.loc.strings.editGroup.toUpperCase()}
        </div>
      </div>
    );
  }
}