import * as React from 'react';
import './Notifications.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import NotificationService from '../../services/NotificationService';
import { INotification } from '../../interfaces/INotification';
import Moment from 'react-moment';

export interface IProps {
  show: boolean;
  appStore?: AppStore;
}

export interface IState {
  showNotificationsBackground: boolean;
}

@inject('appStore')
@observer
export default class Notifications extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showNotificationsBackground: false
    }
  }

  componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      setTimeout(() => {
        this.setState({ showNotificationsBackground: true });
      }, 0)
    };
  }

  render() {
    const notifications = [];
    if (this.props.appStore.notificationsToShow && this.props.appStore.notificationsToShow.length > 0) {
      const notificationsToShow = this.props.appStore.notificationsToShow.sort(function (a, b) {
        return new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime();
      });
      notificationsToShow.forEach((notification: INotification, index) => {
        const receivers = [];
        if (notification.sendToAll) {
          receivers.push(<div className="notificationAdminElementReceiver">{notification.releaseDate ? "Sendt til alle" : "Sendes til alle"}</div>);
        }
        if (notification.receivers && notification.receivers.length > 0) {
          notification.receivers.forEach((receiver: any) => {
            if (receiver.product) {
              if (receiver.teams && receiver.teams.length > 0) {
                receiver.teams.forEach((team: any) => {
                  if (team.selected) {
                    receivers.push(
                      <div className="notificationAdminElementReceiver">{`${receiver.product.title} > ${team.team}`}</div>
                    );
                  }
                })
              } else {
                receivers.push(
                  <div className="notificationAdminElementReceiver">{receiver.product.title}</div>
                );
              }
            }
          })
        }
        notifications.push(
          <div>
            <div className='notificationTime'>
              <Moment fromNow local>{notification.createdAt}</Moment>
            </div>
            <div
              key={notification.objectId}
              className='notificationElement'
              onClick={() => {
                notification.link = notification.link.replace("/products/", "/programs/");
                if (this.props.appStore.readNotification == undefined) {
                  this.props.appStore.readNotification = {
                    userId: this.props.appStore.user.id,
                    notificationIds: [notification.objectId]
                  };
                  NotificationService.createReadNotifications(this.props.appStore.readNotification).then(() => {
                    this.props.appStore.notificationsToShow.splice(index, 1);
                    window.open(notification.link);
                  });
                } else {
                  this.props.appStore.readNotification.notificationIds.push(notification.objectId);
                  NotificationService.updateReadNotifications(this.props.appStore.readNotification).then(() => {
                    this.props.appStore.notificationsToShow.splice(index, 1);
                    window.open(notification.link);
                  });
                }
              }}
            >
              {receivers &&
                <div className='notificationAreaLabel'>
                  {receivers}
                </div>
              }
              <div className='title'>
                {notification.title}
              </div>
              <div className='text'>
                {notification?.text?.replace(/{user}/g, this.props.appStore.user?.name?.split(" ")[0])}
              </div>
              {notification.link &&
                <a href={notification.link}>
                  <div className='pushPopupBtn'>
                    {notification.linkText ? notification.linkText : window.loc.strings.goToURL}
                  </div>
                </a>
              }
            </div>
          </div>
        );
      });
    }
    return (
      <>
        {this.props.appStore.showNotification &&
          <div
            className='notificationsOverlay'
            style={{
              opacity: this.state.showNotificationsBackground ? 0.6 : 0
            }}
          />
        }
        <div
          className='notifications'
          style={{
            top: this.props.appStore.isMobile ? (this.props.appStore.showNotification ? 0 : screen.height) : 50,
            right: this.props.appStore.isMobile ? "unset" : (this.props.appStore.showNotification ? 0 : -500),
          }}
        >
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.showNotification = false;
            }}
          />
          <div className='notificationsContainer'>
            <div className="headline">{window.loc.strings.notifications}</div>
            {notifications}
          </div>
        </div>
      </>
    );
  }

}