import * as React from 'react';
import './PushPopup.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import NativeService from '../../services/NativeService';
import { INotification } from '../../interfaces/INotification';
import NotificationService from '../../services/NotificationService';

export interface IProps {
  appStore?: AppStore;
  close: () => void;
}

export interface IState {
}

@inject('appStore')
@observer
export default class PushPopup extends React.Component<IProps, IState> {

  private pushRead(): void {
    this.props.appStore.notificationsToShow?.forEach((notification: INotification) => {
      if (
        notification.title === this.props.appStore.pushToShow.title &&
        notification.link === this.props.appStore.pushToShow.url &&
        notification.text === this.props.appStore.pushToShow.text &&
        notification.linkText === this.props.appStore.pushToShow.linkText
      ) {
        if (this.props.appStore.readNotification == undefined) {
          this.props.appStore.readNotification = {
            userId: this.props.appStore.user.id,
            notificationIds: [notification.objectId]
          };
          NotificationService.createReadNotifications(this.props.appStore.readNotification).then(() => {
            this.props.appStore.pushToShow = undefined;
            NativeService.pushPopupSuccesfullyOpened();
            this.props.close();
          });
        } else {
          this.props.appStore.readNotification.notificationIds.push(notification.objectId);
          NotificationService.updateReadNotifications(this.props.appStore.readNotification).then(() => {
            this.props.appStore.pushToShow = undefined;
            NativeService.pushPopupSuccesfullyOpened();
            this.props.close();
          });
        }
      }
    });
  }

  render() {
    const pushPopupBox = document.getElementById("PushPopupBox");
    let marginTop;
    if (pushPopupBox) {
      marginTop = `calc(50% - ${pushPopupBox.clientHeight / 2}px`;
    } else {
      this.forceUpdate();
    }
    return (
      <div className="pushPopup">
        <div
          className="pushPopupBackground"
          onClick={() => {
            this.pushRead();
          }}
        />
        <div
          id="PushPopupBox"
          className="pushPopupBox"
          style={{
            top: marginTop
          }}
        >
          {this.props.appStore.pushToShow?.title &&
            <div className='title'>
              {this.props.appStore.pushToShow?.title}
            </div>
          }
          {this.props.appStore.pushToShow?.text &&
            <div className='text'>
              {this.props.appStore.pushToShow?.text?.replace(/{user}/g, this.props.appStore.user?.name?.split(" ")[0])}
            </div>
          }
          {this.props.appStore.pushToShow?.url &&
            <a
              href={this.props.appStore.pushToShow?.url}
              onClick={() => {
                this.pushRead();
              }}
            >
              <div
                className='pushPopupBtn'
              >
                {this.props.appStore.pushToShow?.linkText ? this.props.appStore.pushToShow?.linkText : window.loc.strings.goToURL}
              </div>
            </a>
          }
          <div className="pushPopupCloseButton"
            onClick={() => {
              this.pushRead();
            }}
          >
            Luk
          </div>
        </div>
      </div>
    );
  }

}