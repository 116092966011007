import * as React from 'react';
import './NotificationEditPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../../interfaces/IProduct';
import ProductService from '../../../services/ProductService';
import { ITeam } from '../../../interfaces/ITeam';
import { IAPivot } from '../../../mindjuiceApp/UI/pivot/Pivot';
import AdminService from '../../../services/AdminService';
import { IUser } from '../../../interfaces/IUser';
import UserSearchPopup from '../../../mindjuiceApp/userSearchPopup/UserSearchPopup';
import DateTimeService from '../../../services/DateTimeService';

export interface IProps {
  id: string;
  appStore?: AppStore;
  save: () => void;
  delete: () => void;
}

export interface IState {
  searchValue: string;
  usersToRender: JSX.Element[];
  loadingUsers: boolean;
}

export interface IState {
  selectedAudience: "all" | "teams" | "users";
}

@inject('appStore')
@observer
export default class NotificationEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      selectedAudience: "all",
      searchValue: "",
      usersToRender: [],
      loadingUsers: false,
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.id && props.id !== this.props.id) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.getProducts();
      } else {
        this.props.appStore.getProducts().then(() => {
          this.getProducts();
        });
      }
    }
  }

  private getProducts(): void {
    if (this.props.appStore.notificationToEdit?.receivers?.length > 0) {
      this.setState({ selectedAudience: "teams" });
    }
    this.props.appStore.productToNotify = [];
    this.props.appStore.products.forEach((product: IProduct) => {
      const teams = [];
      ProductService.getTeamsForProduct(product.productId).then((availableTeams: ITeam[]) => {
        if (availableTeams && availableTeams.length > 0) {
          availableTeams.forEach((team: ITeam) => {
            teams.push({
              team: team.team,
              selected: this.isTeamSelected(team)
            });
          });
        }
        this.props.appStore.productToNotify.push(
          {
            product: product,
            selected: this.isProductSelected(product),
            teams: teams
          }
        );
      });
    });
    this.forceUpdate();
  }

  private isProductSelected(product: IProduct): boolean {
    let isProductSelected = false;
    if (this.props.appStore.notificationToEdit && this.props.appStore.notificationToEdit.receivers && this.props.appStore.notificationToEdit.receivers.length > 0) {
      this.props.appStore.notificationToEdit.receivers.forEach((receiverProduct: { product: IProduct, selected: boolean, teams: any[] }) => {
        if (product.productId === receiverProduct.product.productId && receiverProduct.selected) {
          isProductSelected = true;
        }
      });
    }
    return isProductSelected;
  }

  private isTeamSelected(team: ITeam): boolean {
    let isTeamSelected = false;
    if (this.props.appStore.notificationToEdit && this.props.appStore.notificationToEdit.receivers && this.props.appStore.notificationToEdit.receivers.length > 0) {
      this.props.appStore.notificationToEdit.receivers.forEach((receiverProduct: { product: IProduct, selected: boolean, teams: any[] }) => {
        if (team.productId === receiverProduct.product.productId && receiverProduct.teams && receiverProduct.teams.length > 0) {
          receiverProduct.teams.forEach((receivedTeam: any) => {
            if ((receivedTeam?.team?.team ? team?.team === receivedTeam?.team?.team : team?.team === receivedTeam?.team) && receivedTeam.selected) {
              isTeamSelected = true;
            }
          });
        }
      });
    }
    return isTeamSelected;
  }

  render() {
    const productCheckboxes = [];
    if (this.props.appStore.notificationToEdit && this.props.appStore.productToNotify && this.props.appStore.productToNotify.length > 0) {
      this.props.appStore.productToNotify.forEach((product: { product: IProduct, selected: boolean, teams: any[] }, index) => {
        if (product.product.title) {
          productCheckboxes.push(
            <div
              className="checkbox"
            >
              <input
                type="checkbox"
                checked={product.selected}
                onChange={() => {
                  this.props.appStore.productToNotify[index].selected = !this.props.appStore.productToNotify[index].selected;
                  this.props.appStore.notificationToEdit.sendToAll = false;
                }}
                style={{
                  width: 20,
                  marginTop: -2
                }}
              />
              {product.product.title}
            </div>
          );
          if (product.teams && product.teams.length > 0) {
            product.teams.forEach((team: { team: any, selected: boolean }, teamIndex) => {
              productCheckboxes.push(
                <div
                  className="checkbox"
                  style={{
                    marginLeft: 40,
                    opacity: product.selected ? 0.5 : 1
                  }}
                >
                  <input
                    type="checkbox"
                    checked={product.selected ? product.selected : team.selected}
                    onChange={() => {
                      this.props.appStore.productToNotify[index].teams[teamIndex].selected = !this.props.appStore.productToNotify[index].teams[teamIndex].selected;
                      this.props.appStore.notificationToEdit.sendToAll = false;
                    }}
                    style={{
                      width: 20,
                      marginTop: -2
                    }}
                  />
                  {team.team}
                </div>
              );
            });
          }
        }
      });
    }
    const pushReceivers = [];
    if (this.props.appStore.notificationToEdit?.pushReceivers?.length > 0) {
      this.props.appStore.notificationToEdit.pushReceivers.forEach((pushReceiver: string) => {
        pushReceivers.push(
          <div
            key={"usersWithAccess_" + pushReceiver}
            className="emailLabel"
          >
            {pushReceiver}
            <div
              className="emailLabelDeleteBtn"
              onClick={() => {
                this.props.appStore.notificationToEdit.pushReceivers = this.props.appStore.notificationToEdit?.pushReceivers.filter(savedPushReceivers => savedPushReceivers !== pushReceiver);
              }}
            />
          </div>
        );
      });
    }
    return (
      <div>
        <div
          className="notificationEditPanelWrapper"
          style={{
            visibility: this.props.appStore.notificationToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.notificationToEdit = undefined;
          }}
        />
        <div
          className="notificationEditPanel"
          style={{
            right: this.props.appStore.notificationToEdit != undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.notificationToEdit = undefined}
          />
          <h3>Send notifikation</h3>
          {this.props.appStore.notificationToEdit != undefined &&
            <div style={{
              position: "relative",
              float: "left",
              width: "100%"
            }}>
              <div className="inputFieldLabel">Overskrift</div>
              <input
                type="text"
                value={this.props.appStore.notificationToEdit.title}
                onChange={(event: any) => {
                  this.props.appStore.notificationToEdit.title = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Tekst</div>
              {this.state.selectedAudience !== "all" &&
                <div className='infoBox'>
                  NB: Det er nu muligt at indsætte en &#123;user&#125; variabel i indholdsfeltet. Denne vil blive erstattet med brugerens fornavn, når notifikationen sendes. For at indsætte brugerens fornavn indsættes <strong>&#123;user&#125;</strong> i teksten. Fx "Hej <strong>&#123;user&#125;</strong>, der er nyt indhold...". Denne funktion virker kun, når der sendes beskeder til hold eller udvagte brugere. Ikke når der sendes til alle.
                </div>
              }
              <textarea
                onChange={(event: any) => this.props.appStore.notificationToEdit.text = event.target.value}
              >
                {this.props.appStore.notificationToEdit?.text}
              </textarea>
              <div className="inputFieldLabel">Link</div>
              <input
                type="text"
                value={this.props.appStore.notificationToEdit.link}
                onChange={(event: any) => {
                  this.props.appStore.notificationToEdit.link = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Tekst til linkknappen</div>
              <input
                type="text"
                value={this.props.appStore.notificationToEdit.linkText}
                onChange={(event: any) => {
                  this.props.appStore.notificationToEdit.linkText = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Udløbsdato</div>
              <input
                type={"date"}
                id="start"
                name="trip-start"
                value={DateTimeService.ConvertToYYYYMMDD(this.props.appStore.notificationToEdit.expirationeDate)}
                onChange={(date: any) => {
                  if (date.target.value && date.target.value !== "") {
                    this.props.appStore.notificationToEdit.expirationeDate = new Date(date.target.value);
                  }
                }}
              />
              <div
                className="inputFieldLabel"
                style={{
                  marginBottom: 10
                }}
              >
                Modtagere
              </div>
              <div className="checkbox">
                <div
                  className="radioButton"
                >
                  <input
                    type="radio"
                    checked={this.state.selectedAudience === "all"}
                    onChange={() => {
                      this.props.appStore.notificationToEdit.sendToAll = true;
                      this.setState({ selectedAudience: "all" });
                      if (this.props.appStore.productToNotify) {
                        this.props.appStore.productToNotify.forEach((product: { product: IProduct, selected: boolean }, index) => {
                          product.selected = false;
                        });
                      }
                    }}
                  />
                  Send til alle
                </div>
                <div
                  className="radioButton"
                >
                  <input
                    type="radio"
                    checked={this.state.selectedAudience === "teams"}
                    onChange={() => {
                      this.props.appStore.notificationToEdit.sendToAll = false;
                      this.setState({ selectedAudience: "teams" });
                    }}
                  />
                  Send til program- eller holdmedlemmer
                </div>
                <div
                  className="radioButton"
                >
                  <input
                    type="radio"
                    checked={this.state.selectedAudience === "users"}
                    onChange={() => {
                      this.props.appStore.notificationToEdit.sendToAll = false;
                      this.setState({ selectedAudience: "users" });
                    }}
                  />
                  Send til udvalgte brugere
                </div>
              </div>
              {this.state.selectedAudience === "teams" &&
                <div
                  style={{
                    marginTop: 20,
                    float: "left",
                    clear: "both"
                  }}
                >
                  <div
                    className="inputFieldLabel"
                    style={{
                      marginBottom: 10
                    }}
                  >
                    Vælg program eller hold
                  </div>
                  {productCheckboxes}
                </div>
              }
              {this.state.selectedAudience === "users" &&
                <div
                  style={{
                    marginTop: 20,
                    float: "left",
                    clear: "both"
                  }}
                >
                  <div className="inputFieldLabel">Vælg modtagere</div>
                  <input
                    type="text"
                    placeholder="Søg efter brugere (email)"
                    value={undefined}
                    style={{
                      width: "100%"
                    }}
                    onChange={(event: any) => {
                      this.setState({ searchValue: event.target.value.toLowerCase() });
                    }}
                  />
                  <div
                    className="searchButton"
                    onClick={() => {
                      const usersToRender = [];
                      this.setState({ loadingUsers: true });
                      AdminService.getUsers(this.state.searchValue).then((users: IUser[]) => {
                        if (users && users.length > 0) {
                          users.forEach(async (user: IUser) => {
                            usersToRender.push(
                              <div
                                className='emailLabel'
                                onClick={() => {
                                  if (!this.props.appStore.notificationToEdit.pushReceivers) {
                                    this.props.appStore.notificationToEdit.pushReceivers = [];
                                  }
                                  this.props.appStore.notificationToEdit.pushReceivers.push(user.username);
                                  this.setState({
                                    usersToRender: []
                                  });
                                }}
                              >
                                {`${user.name} (${user.username})`}
                              </div>
                            );
                          });
                          this.setState({ usersToRender, loadingUsers: false });
                        } else {
                          this.setState({ loadingUsers: false });
                        }
                      });
                    }}
                  />
                  {pushReceivers}
                </div>
              }
              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <button
                  onClick={() => this.props.save()}
                >
                  {this.props.appStore.notificationToEdit.objectId !== undefined && this.props.appStore.notificationToEdit.objectId !== "new" ? "Opdater" : "Opret"}
                </button>
                <button
                  style={{
                    marginLeft: 10
                  }}
                  onClick={() => {
                    this.props.appStore.notificationToEdit = undefined;
                    this.props.appStore.productToNotify = undefined;
                  }}
                >
                  Annuller
                </button>
                <button
                  onClick={() => this.props.delete()}
                  style={{
                    backgroundColor: "gray",
                    marginLeft: 10,
                    display: this.props.appStore.notificationToEdit && this.props.appStore.notificationToEdit.objectId != undefined ? "block" : "hidden"
                  }}
                >
                  Slet
                </button>
              </div>
            </div>
          }
        </div>
        {this.state.usersToRender?.length > 0 &&
          <UserSearchPopup
            users={this.state.usersToRender}
            close={() => {
              this.setState({
                usersToRender: []
              });
            }}
          />
        }
      </div>
    );
  }
}