import { IGroup } from "../interfaces/IGroup";
import { IGroupMemberInfo } from "../interfaces/IGroupMemberInfo";
import { IUser } from "../interfaces/IUser";

export default class GroupService {

  public static getGroups(): Promise<IGroup[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getGroupsForUser(user: string): Promise<IGroup[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/?where={"members":{"$in":["${user}"]}}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getGroup(id: string): Promise<IGroup> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/${id}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createGroup(group: IGroup): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          group
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateGroup(group: IGroup): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/${group.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          type: group.type,
          name: group.name,
          description: group.description,
          members: group.members,
          admins: group.admins,
          allowMembersToSeeResult: group.allowMembersToSeeResult
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteGroup(id: string): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Groups/${id}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  // Group member info

  public static getGroupMemberInfoForGroup(id: string): Promise<IGroupMemberInfo[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/GroupMemberInfo/?where={\"groupId\":\"${id}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getMyGroupMemberInfo(id: string, userId: string): Promise<IGroupMemberInfo[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/GroupMemberInfo/?where={\"groupId\":\"${id}\",\"userId\":\"${userId}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createGroupMemberInfo(groupMemberInfo: IGroupMemberInfo): Promise<string> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/GroupMemberInfo/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          groupMemberInfo
        )
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return  response.json();
        }
      }).then(response => {
        resolve(response.objectId);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }


  public static deleteGroupMemberInfo(id: string): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/GroupMemberInfo/${id}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }
}