import * as React from 'react';
import './Profile.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { IType } from '../../interfaces/IType';
import { Link } from 'react-router-dom';

export interface IProps {
  show: boolean;
  appStore?: AppStore;
}

export interface IState {
  showProfileBackground: boolean;
}

@inject('appStore')
@observer
export default class Profile extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      showProfileBackground: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.show && !this.props.show) {
      setTimeout(() => {
        this.setState({ showProfileBackground: true });
      }, 0)
    };
  }

  private getTypeName(type: number): string {
    switch (type) {
      case 1:
        return window.loc.strings.type1;
      case 2:
        return window.loc.strings.type2;
      case 3:
        return window.loc.strings.type3;
      case 4:
        return window.loc.strings.type4;
      case 5:
        return window.loc.strings.type5;
      case 6:
        return window.loc.strings.type6;
      case 7:
        return window.loc.strings.type7;
      case 8:
        return window.loc.strings.type8;
      case 9:
        return window.loc.strings.type9;
      default:
        break;
    }
  }

  render() {
    return (
      <>
        {this.props.appStore.showProfile &&
          <div
            className='profileOverlay'
            style={{
              opacity: this.state.showProfileBackground ? 0.6 : 0
            }}
          />
        }
        <div
          className='profile'
          style={{
            top: this.props.appStore.isMobile ? (this.props.appStore.showProfile ? 0 : screen.height) : 50,
            right: this.props.appStore.isMobile ? "unset" : (this.props.appStore.showProfile ? 0 : -500),
            height: this.props.appStore.isMobile ? "100%" : "calc(100% - 50px)",
            overflow: "hidden"
          }}
        >
          <div
            className='closeButton'
            onClick={() => {
              this.props.appStore.showProfile = false;
            }}
          />
          <div className="profileWrapper">
            <div className="headline">{window.loc.strings.yourProfile}</div>
            <div className='areaHeadline'>{window.loc.strings.yourTestResult.toUpperCase()}</div>
            <div className='profileEnneagramTypeResultBox'>

              <div
                className="enneagrammyResultNumber"
                style={{
                  backgroundColor: this.props.appStore.typeTestColorLighter
                }}
              >
                {this.props.appStore.selectedType}
              </div>
              <div className="enneagrammyResultName">
                {this.getTypeName(this.props.appStore.selectedType)}
              </div>
              {this.props.appStore.isMobile ?
                <a
                  href="https://apps.apple.com/us/app/enneagrammy/id1033881087"
                  target="_blank"
                >
                  <div
                    className='profileButton'
                    style={{
                      backgroundColor: "#064859",
                      color: "#ffffff",
                    }}
                  >
                    <div
                      className='profileButtonIcon'
                      style={{
                        backgroundImage: 'url("https://dojo.mindjuice.com/images/tabbar/tests.png")'
                      }}
                    />
                    <div className='profileButtonText' >
                      {window.loc.strings.testIntroStartBtn}
                    </div>
                  </div>
                </a>
                :
                <Link
                  to={`${this.props.appStore.environment}/tests/19067/test`}
                >
                  <div
                    className='profileButton'
                    style={{
                      backgroundColor: "#064859",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      this.props.appStore.startTest();
                      this.props.appStore.testProgression = "Test";
                      this.props.appStore.showEnneagrammyTest = true;
                    }}
                  >
                    <div
                      className='profileButtonIcon'
                      style={{
                        backgroundImage: 'url("https://dojo.mindjuice.com/images/tabbar/tests.png")'
                      }}
                    />
                    <div className='profileButtonText' >
                      {window.loc.strings.testIntroStartBtn}
                    </div>
                  </div>
                </Link>
              }
              {this.props.appStore.isMobile ?
                <a
                  href="https://apps.apple.com/us/app/enneagrammy/id1033881087"
                  target="_blank"
                >
                  <div
                    className='profileButton'
                  >
                    <div
                      className='profileButtonIcon'
                      style={{
                        backgroundImage: 'url("https://dojo.mindjuice.com/images/result-icon.png")'
                      }}
                    />
                    <div className='profileButtonText' >
                      {window.loc.strings.resultTextBtn}
                    </div>
                  </div>
                </a>
                :
                <Link
                  to={`${this.props.appStore.environment}/tests/19067/results`}
                >
                  <div
                    className='profileButton'
                    onClick={() => {
                      this.props.appStore.selectedTypeInfoTab = "result";
                      this.props.appStore.showEnneagrammyTestResults = true;
                      this.props.appStore.selectedTestResult = this.props.appStore.myResult;
                    }}
                  >
                    <div
                      className='profileButtonIcon'
                      style={{
                        backgroundImage: 'url("https://dojo.mindjuice.com/images/result-icon.png")'
                      }}
                    />
                    <div className='profileButtonText' >
                      {window.loc.strings.resultTextBtn}
                    </div>
                  </div>
                </Link>
              }
            </div>
          </div>
        </div>
      </>
    );
  }

}