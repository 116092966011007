declare global {
  interface Window { loc: LocalizationService; }
}

export class LocalizationService {

  public strings = {
    language: undefined,
    frontpageEnneagramTestHeaderHeadline: undefined,
    frontpageEnneagramTestHeaderText: undefined,
    frontpageEnneagramTestHeaderActionButton: undefined,
    frontpageEnneagramTestActionBarButton: undefined,
    frontpageAboutHeadline: undefined,
    frontpageAboutText: undefined,
    loginPopupText: undefined,
    signUp: undefined,
    logIn: undefined,
    logOut: undefined,
    logingIn: undefined,
    name: undefined,
    email: undefined,
    password: undefined,
    passwordAgain: undefined,
    loginCreateFreeAccount: undefined,
    signingUp: undefined,
    hello: undefined,
    testResultFrom: undefined,
    enneagramTestWelcome: undefined,
    newTest: undefined,
    results: undefined,
    testHeadline: undefined,
    testHeadlineSplitTest: undefined,
    testHeadlineResult: undefined,
    calculatingResult: undefined,
    yourTestResult: undefined,
    testResultInfo: undefined,
    enneagramVideo: undefined,
    type1Video: undefined,
    type2Video: undefined,
    type3Video: undefined,
    type4Video: undefined,
    type5Video: undefined,
    type6Video: undefined,
    type7Video: undefined,
    type8Video: undefined,
    type9Video: undefined,
    type1Test: undefined,
    type2Test: undefined,
    type3Test: undefined,
    type4Test: undefined,
    type5Test: undefined,
    type6Test: undefined,
    type7Test: undefined,
    type8Test: undefined,
    type9Test: undefined,
    deletePopupHeadline: undefined,
    delete: undefined,
    cancel: undefined,
    yourResults: undefined,
    createAFreeAccount: undefined,
    testIntroHeadline: undefined,
    testIntroStartBtn: undefined,
    resultTextHeadline: undefined,
    resultText: undefined,
    resultTextBtn: undefined,
    menuItemTests: undefined,
    menuItemArchieve: undefined,
    type1: undefined,
    type2: undefined,
    type3: undefined,
    type4: undefined,
    type5: undefined,
    type6: undefined,
    type7: undefined,
    type8: undefined,
    type9: undefined,
    allResults: undefined,
    latestResult: undefined,
    resetPassword: undefined,
    frontpageProductHeaderHeadline: undefined,
    frontpageProductHeaderText: undefined,
    frontpageProductHeaderActionButton: undefined,
    deleteAccount: undefined,
    deleteAccountPopupHeadline: undefined,
    menuItemMeditations: undefined,
    menuItemPodcasts: undefined,
    frontpageEnneagramTestHeaderActionButtonResults: undefined,
    podcastsHeadline: undefined,
    podcastsTeaser: undefined,
    goToMIndjuiceAcademy: undefined,
    menuItemAdmin: undefined,
    menuItemCourses: undefined,
    menuItemUsers: undefined,
    menuItemSpeaks: undefined,
    menuItemAllTests: undefined,
    mindjuiceSurvey: undefined,
    open: undefined,
    buyAccess: undefined,
    checkingAccess: undefined,
    edit: undefined,
    readMore: undefined,
    view: undefined,
    views: undefined,
    loginHeadline: undefined,
    loginDescription: undefined,
    joinTheTribe: undefined,
    createAUser: undefined,
    iHaveAUser: undefined,
    showFAQ: undefined,
    iAcceptThePrivacy: undefined,
    personalDataPolicy: undefined,
    forgottenPassword: undefined,
    comingSoon: undefined,
    team: undefined,
    watchVideos: undefined,
    nameTestHeadline: undefined,
    save: undefined,
    nameYourTest: undefined,
    myTest: undefined,
    by: undefined,
    update: undefined,
    details: undefined,
    seeAllResults: undefined,
    takeATestNow: undefined,
    tabBarTest: undefined,
    tabBarResults: undefined,
    tabBarAbout: undefined,
    tabBarLogOut: undefined,
    tabBarMore: undefined,
    tabBarFAQ: undefined,
    tabBarProfile: undefined,
    myResult: undefined,
    openShop: undefined,
    ftpAccess: undefined,
    loadingMindPaper: undefined,
    tabBarDeleteUser: undefined,
    mindPaperSave: undefined,
    mindPaperWrite: undefined,
    mindPaperOverview: undefined,
    mindPaperEdit: undefined,
    changeLanguage: undefined,
    changeLanguagePopupHeadline: undefined,
    Danish: undefined,
    English: undefined,
    subscrineNow: undefined,
    subscription: undefined,
    changeSubscription: undefined,
    restore: undefined,
    subscriptionDisclaimer: undefined,
    restoreFailed: undefined,
    EULA: undefined,
    loginUser: undefined,
    userProfile: undefined,
    signUpNow: undefined,
    programPivotAbout: undefined,
    programPivotContent: undefined,
    programPivotVideos: undefined,
    loading: undefined,
    tabBarPrograms: undefined,
    tabBarTests: undefined,
    tabBarPodcasts: undefined,
    tabBarMeditations: undefined,
    tabBarToday: undefined,
    programsHeader: undefined,
    testsHeader: undefined,
    testsHeaderWeb: undefined,
    podcastsHeader: undefined,
    meditationsHeader: undefined,
    welcome: undefined,
    video: undefined,
    videos: undefined,
    podcastSectionContinue: undefined,
    podcastSectionMindjuice: undefined,
    podcastSectionSpeakers: undefined,
    meditationsAll: undefined,
    completedThisEducation: undefined,
    module: undefined,
    modules: undefined,
    showAllProgramsButton: undefined,
    hasSignedUpForThisEvent: undefined,
    yourEducations: undefined,
    upcommingEvents: undefined,
    showLessButton: undefined,
    share: undefined,
    notifications: undefined,
    yourProfile: undefined,
    result: undefined,
    typeInfo: undefined,
    allResults2: undefined,
    dates: undefined,
    programPivotExam: undefined,
    programPivotInfo: undefined,
    settings: undefined,
    profileSettings: undefined,
    loggedInAs: undefined,
    languageSettings: undefined,
    downloadMobileApp: undefined,
    links: undefined,
    homepage: undefined,
    faq: undefined,
    sharePodcast: undefined,
    shareVideo: undefined,
    tabBarNotes: undefined,
    goToURL: undefined,
    updatePassword: undefined,
    newPassword: undefined,
    newPasswordAgain: undefined,
    noAccess: undefined,
    oldPassword: undefined,
    wrongPassword: undefined,
    wrongPasswordDescription: undefined,
    tryAgain: undefined,
    resetWithEmail: undefined,
    thereIsNewContentFor: undefined,
    hideMembers: undefined,
    showMembers: undefined,
    oneMemeber: undefined,
    members: undefined, 
    create: undefined,
    searchForUsers: undefined,
    selectReceivers: undefined,
    groupMembers: undefined,
    shareType: undefined,
    seeGroupResults: undefined,
    editGroup: undefined,
    addGroupMembers: undefined,
    messageToMembers: undefined,
    groupName: undefined,
    updateGroup: undefined,
    createGroup: undefined,
    groups: undefined,
    allowMembersToSeeResult: undefined
  };

  public constructor() {
    let language;
    if (localStorage.getItem("language")) {
      language = localStorage.getItem("language");
    } else {
      localStorage.setItem("language", this.setDefaultLanguage());
    }
    const defaultLanguage = this.setDefaultLanguage();
    let localizedStringsEnglish;
    const localizedStrings = require(`../localization/${language ? language : defaultLanguage}.json`);
    for (const key in this.strings) {
      if (this.strings.hasOwnProperty(key)) {
        if (localizedStrings[key]) {
          // String is localized
          this.strings[key] = localizedStrings[key];
        } else {
          // String is not localized
          if (defaultLanguage !== 'English') {
            // Use English localization
            if (localizedStringsEnglish === undefined) {
              // Load English strings if not loaded already
              localizedStringsEnglish = require("../localization/English.json");
            }
            if (localizedStringsEnglish[key]) {
              this.strings[key] = localizedStringsEnglish[key];
            } else {
              // Should never reach here. Means string is not localized in language neither in English
              this.strings[key] = key;
            }
          } else {
            // Should never reach here. Means string is not localized in language neither in English
            this.strings[key] = key;
          }
        }
      }
    }
  }

  private setDefaultLanguage(): 'Danish' | 'English' {
    switch (navigator.language.substring(0, 2)) {
      case 'en':
        return 'English';
      case 'da':
        return 'Danish';
      default:
        return 'English';
    }
  }

}