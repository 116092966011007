import * as React from 'react';
import './Tests.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import { IProduct } from '../../interfaces/IProduct';
import Card from '../card/Card';
import * as ReactGA from 'react-ga';
import Header from '../header/Header';
import { CardViewService } from '../../services/CardView.service';
import LogoBar from '../logoBar/LogoBar';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  testsToRender: JSX.Element[];
  containerWidth: number;
}

@inject('appStore')
@observer
export default class Tests extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      testsToRender: [],
      containerWidth: undefined
    }
  }

  public componentDidMount(): void {
    this.props.appStore.selectedTab = 2;
    this.props.appStore.updateBackground();
    if (this.props.appStore.tests && this.props.appStore.tests.length > 0) {
      this.getTests();
    } else {
      this.props.appStore.getTests().then(() => {
        this.getTests();
      });
    }
    // Resize
    window.addEventListener('resize', () => this.resize());
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`tests`));
    } catch {
      this.resize();
    }
    window.addEventListener('popstate', () => this.popstate(), false);
  }

  private popstate(): void {
    if (this.props.appStore.showEnneagrammyTestResults) {
      this.props.appStore.showEnneagrammyTestResults = false;
    }
    else if (this.props.appStore.showEnneagrammyTest) {
      this.props.appStore.showEnneagrammyTest = false;
    }
    else {
      this.props.appStore.showEnneagrammy = undefined;
    }
  }

  private resize(): void {
    const programsContainer = document.getElementById("tests");
    this.setState({ containerWidth: programsContainer?.clientWidth - 80 }, () => this.getTests());
  }

  private getTests(): void {
    const testsToRender = [];
    let language = "danish";
    if (localStorage.getItem("language")) {
      language = localStorage.getItem("language").toLowerCase();
    }
    const [numberOfCardsPerRow, maxCardWidthAvailable] = new CardViewService().getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.state.containerWidth, 20, 300, 6);
    let currentRowNumber = 1;
    this.props.appStore.tests.forEach((test: IProduct) => {
      const title = language !== "danish" && test.localizedTitle && test.localizedTitle[language] ? test.localizedTitle[language] : test.title;
      const teaser = language !== "danish" && test.localizedTeaser && test.localizedTeaser[language] ? test.localizedTeaser[language] : test.teaser;
      const image = language !== "danish" && test.localizedImage && test.localizedImage[language] ? test.localizedImage[language] : test.image;;
      testsToRender.push(
        <Card
          key={test.objectId}
          width={this.props.appStore.isMobile ? screen.availWidth - 40 : this.props.appStore.tests?.length === 1 ? 350 : (maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
          marginRight={currentRowNumber == numberOfCardsPerRow || this.props.appStore.isMobile ? 0 : 20}
          area="tests"
          id={test.productId}
          title={title}
          image={image}
          type={test.type}
          teaser={teaser ? teaser.replace(/<\/?[^>]+(>|$)/g, "") : "  "}
          product={test}
          open={() => {
            ReactGA.event({
              category: 'Tests',
              action: `User opened test: ${test.title}`
            });
            if (!this.props.appStore.isMobile) {
              this.props.appStore.showEnneagrammy = test;
            }
          }}
        />
      );
      if (currentRowNumber == numberOfCardsPerRow) {
        currentRowNumber = 1;
      } else {
        currentRowNumber++;
      }
    });
    this.setState({ testsToRender });
  }

  render() {
    let testId = "";
    if (location.href?.split("/tests/")?.length > 1) {
      testId = location.href?.split("/tests/")[1];
    }
    return (
      <div
        id="tests"
        className='tests'
        style={{
          top: this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50,
          left: this.props.appStore.isMobile ? 0 : 250,
          height: this.props.appStore.isMobile ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 80 : 130}px)` : (this.props.appStore.podcastToPlay ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 101 : 151}px)` : `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50}px)`)
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={this.props.appStore.isMobile ? window.loc.strings.testsHeader : window.loc.strings.testsHeaderWeb}
        />
        <div className="programsBox">
          <div className="container">
            {this.state.testsToRender}
          </div>
        </div>
      </div>
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
    window.removeEventListener('popstate', () => this.popstate());
  }

}